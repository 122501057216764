.expand {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 3px;
    padding-right: 3px;
}
.expand:hover {
    background: var(--red-bg);
}
.expand.reader {
    color: var(--red-text);
    cursor: pointer;
}
.mirror {
    transform: scaleX(-1);
}
