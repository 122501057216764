.app-bar {
    border-bottom: 1px solid var(--red-bg);
    background: white;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    align-items: center;
}
.main-logo {
    margin-left: 20px;
    display: flex;
    align-items: center;
}
span.tool-logo {
    font-weight: bold;
}
.tool-credit-url:hover {
    text-decoration: underline;
}
.tool-credit-url {
    color: inherit;
    text-decoration: none;
}
span.tool-credit {
    color: var(--red-text);
    margin-left: 1em;
    background: var(--red-bg);
    padding: 0.5em 1em;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 0.7em;
}
