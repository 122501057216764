.problem-list {
    margin: 0;
    padding: 0;
    width: 15%;
    list-style: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 100%;
    overflow-y: scroll;
}

.problem-list-menu-item.is-scrolled {
    box-shadow: 0px -10px 15px black;
}
.problem-list-menu-item {
    letter-spacing: 1.5px;
    padding: 0 0 1rem;
    text-transform: uppercase;
    color: var(--text-secondary);
    text-align: center;
    position: sticky;
    top: 0;
    background: rgb(255 255 255 / 75%);
    padding-top: 0.5rem;
    backdrop-filter: blur(3px) saturate(150%);
}
