.problem-card:hover {
    cursor: pointer;
    color: var(--red-text);
}
.problem-card {
    background-color: #fff;
    border-left: 2px solid #e0e0e6;
    align-items: center;
    justify-content: center;
    display: flex;
    box-sizing: border-box;
    font-size: 1em;
    user-select: none;
    padding: 0.5rem 1rem;
    margin-left: 0.5rem;
    color: rgb(167, 167, 167);
}
.active-problem {
    font-weight: bold;
    color: var(--text-primary);
    background-color: var(--red-bg);
    font-weight: bold;
    border-left: 2px solid var(--red-text);
}
