:root {
    --red-text: #e80840;
    --red-bg: rgba(232, 8, 64, 0.063);
    --text-primary: #15141a;
    --text-secondary: #5b5b66;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Inter, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
