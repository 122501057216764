.readme {
    display: flex;
    width: 35%;
    background-color: var(--red-bg);
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    flex-direction: column;
    font-size: 0.75em;
    padding: 15px;
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
}
.readme h2 {
    position: sticky;
    top: 0;
    margin-top: 0;
    background: white;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    color: var(--text-secondary);
    border: 1px solid var(--red-text);
    font-weight: normal;
}
.readme p {
    margin: 0;
}
.readme p code {
    word-break: break-all;
}
.readme img {
    max-width: 90%;
}
.readme code {
    background-color: #f7f7f7;
    color: rgb(216, 112, 112);
    font-family: monospace !important;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
}
.readme.fullscreen h2 {
    position: static;
}
.readme.fullscreen img {
    width: 400px;
}
.readme.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    font-size: 1em;
    padding: 40px;
}
